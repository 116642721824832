<template>
  <div>
    <Editor ref="editor" :url="url" :fields="fields" :filters="filters"
            :args="args"
            :testo-list="$route.params.tipo.toUpperCase()"
            testo-insert="Nuovo ordinativo"
            testo-update="Modifica ordinativo"
            testo-delete="Vuoi cancellare l'ordinativo?"
            :checkbox="true"
            @refresh="editor_refresh"
            :globalActions="globalActions"
            @globalAction="globalAction"
            :no-insert="!$auth('amministratore')"
            :no-update="!$auth('amministratore')"
            :no-delete="!$auth('amministratore')">
      <template #item.data_pianificazione="{ item }">
        <LocalDate time :date="item.data_pianificazione"></LocalDate>
      </template>
      <template #item.data_audit="{ item }">
        <LocalDate :date="item.data_audit"></LocalDate>
      </template>
      <template #item.data_validazione="{ item }">
        <LocalDate :date="item.data_validazione"></LocalDate>
      </template>
      <template #item.interrotta="{ item }">
        <Boolean v-model="item.interrotta"></Boolean>
      </template>
      <template #item.cambio_codice="{ item }">
        <Boolean v-model="item.cambio_codice"></Boolean>
      </template>
      <template #item.descrizione="{ item }">
        <div class="descrizione">
          <TextTruncate width="15vw" :text="item.descrizione_lotto"></TextTruncate>
          <a :href="'https://www.google.it/maps/place/' + item.s_referente.indirizzo + ',' + item.s_referente.comune"
             target="_blank">
            {{ item.s_referente.indirizzo }}
            {{ item.s_referente.comune }}
            {{ item.s_referente.provincia }}
          </a>
          {{ item.s_regione.descrizione }}
        </div>
      </template>
      <template #item.referente="{item}">
        <a href="#" @click.prevent="contatto(item)">{{ item.referente_cognome }} {{ item.referente_nome }}</a>
      </template>
      <template #item.stato="{item}">
        <OrdinativoStato :stato="item.stato"></OrdinativoStato>
      </template>
      <template #item.stato_requisiti="{item}">
        {{ item.stato_requisiti.eseguiti }} / {{ item.stato_requisiti.totali }}
      </template>
      <template #item.nc.gravi_perc="{item}">
                <span v-if="item.nc.gravi">
                    {{ item.nc.gravi / item.stato_requisiti.totali * 100 }} %
                </span>
      </template>
      <template #extra>
        <v-container>
          <v-row>
            <v-col cols="4">
              <div>
                <p>
                  <v-chip small class="mr-2">{{ convenzione.codice }}</v-chip>
                  {{ convenzione.nome }}<br>
                  {{ convenzione.tipo }}<br>
                  {{ convenzione.tipo_attivita }}
                </p>
              </div>
            </v-col>
            <v-col cols="4" v-if="$auth('amministatore')">
              <v-card>
                <v-data-table
                    :headers="[{ text: 'Non conformità', value: 'livello' }, { text: 'Totale', value: 'totale' }]"
                    :items="statistiche.livelli"
                    hide-default-footer
                    disable-pagination
                    dense
                >
                </v-data-table>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card>
                <v-data-table
                    :headers="[{ text: 'Stato', value: 'stato' }, { text: 'Totale', value: 'totale' }]"
                    :items="statistiche.stati"
                    hide-default-footer
                    disable-pagination
                    dense
                >
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-if="!$auth(['pianificatore', 'consip'])" #top>
        <v-btn color="success"
               class="mx-2"
               @click="export_rapporto"
               :loading="loadingRapporto"
        >
          Export Rapporto
        </v-btn>
        <v-btn color="success"
               class="mx-2"
               @click="export_convenzione"
               v-if="$auth('amministratore')"
        >
          Export Convenzione
        </v-btn>
        <v-dialog v-model="dialogUpload" max-width="500px">
          <template #activator="{ on, attrs }">
            <v-btn v-if="$auth('amministratore')"
                   color="success"
                   class="mx-2"
                   v-bind="attrs"
                   v-on="on">
              <v-icon>mdi-plus</v-icon>
              Importa file
            </v-btn>
          </template>
          <v-card class="pa-3">
            <v-card-title>Importazione file</v-card-title>
            <v-card-text>
              <v-file-input v-model="file"></v-file-input>
            </v-card-text>
            <v-card-actions>
              <v-btn color="gray" @click="dialogUpload=false">Annulla</v-btn>
              <v-spacer></v-spacer>
              <v-progress-circular
                  v-if="loading_file"
                  indeterminate
                  color="primary"
              ></v-progress-circular>
              <v-btn color="primary" :disabled="!file || loading_file" @click="upload">Conferma</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template #form="{instance}">
        <Relation v-model="instance.ispettore" url="/backend/autocomplete/userprofile" label="Ispettore"
                  :filters="{ruoli:'amministratore,ispettore'}"></Relation>
        <v-text-field v-model="instance.note" label="Note"></v-text-field>
        <v-text-field v-model="instance.referente_nome" label="Referente Nnome"></v-text-field>
        <v-text-field v-model="instance.referente_cognome" label="Referente Cognome"></v-text-field>
        <v-text-field v-model="instance.referente_email" label="Referente Email"></v-text-field>
        <v-text-field v-model="instance.referente_telefono" label="Referente Telefono"></v-text-field>
        <v-text-field v-model="instance.referente_fax" label="Referente Fax"></v-text-field>
        <v-select v-model="instance.stato" :items="stati"></v-select>
      </template>
      <template #actions="{item}">
        <template v-if="item.stato === 'pianificato'">
          <v-btn small color="primary" class="mr-2"
                 @click="download_pdf(item)">
            Scarica PDA
          </v-btn>
        </template>
        <template v-if="item.stato === 'eseguito' && !$auth(['consip', 'ispettore'])">
          <Confirm message="Validare il rapporto?" @confirm="valida_rapporto(item)">
            <template #activator="{on, attrs}">
              <v-btn v-on="on" v-bind="attrs" small color="success" class="mr-2">
                Valida
              </v-btn>
            </template>

          </Confirm>
        </template>
        <template
            v-if="['eseguito', 'compilato', 'validato', 'validato-consip', 'non-validato-consip'].includes(item.stato)">
          <router-link
              :to="{name: 'ordinativo_checklist', params: {id: item.id}}">
            <v-btn small color="primary" class="mr-2">
              Dettaglio
            </v-btn>
          </router-link>
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-2" color="primary" small v-bind="attrs" v-on="on">
                <v-icon small class="mr-1">mdi-arrow-down-circle</v-icon>
                Download
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="item.rapporto_pdf && !$auth('consip')" @click="download_rapporto(item)">
                Rapporto
              </v-list-item>
              <v-list-item v-if="item.rapporto_validato_pdf && $auth(['amministratore', 'consip'])"
                           @click="download_rapporto_validato(item)">
                Rapporto validato
              </v-list-item>
              <v-list-item @click="download_allegati(item)" v-if="$auth(['amministratore'])">
                Allegati
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </template>
      <template #expanded-item="{item}">
        {{ item }}
      </template>
    </Editor>
    <Confirm v-model="dialogAssocia" message="Associa a gruppo" @confirm="associa">
      <Relation v-model="associaGruppo" url="/backend/autocomplete/gruppo" label="Gruppo"></Relation>
    </Confirm>
    <Confirm v-model="dialogAssegna" message="Assegna ordinativi" @confirm="assegna">
      <Relation v-model="assegnaUser" url="/backend/autocomplete/userprofile" label="Utente"
                :filters="{ruoli:'amministratore,ispettore,pianificatore'}"></Relation>
    </Confirm>
    <Confirm v-model="dialogPianifica" message="Pianifica valutazione" @confirm="pianifica" width="700">
      <v-row justify="space-around">
        <v-date-picker v-model="pianificaData"></v-date-picker>
        <v-time-picker format="24hr" v-model="pianificaOra"></v-time-picker>
      </v-row>
    </Confirm>
    <Confirm v-model="dialogValidaConsip" message="Motivo Validazione Ordinativo!" @confirm="validaConsip"
             width="700">
      <v-row justify="space-around">
        <v-textarea v-model="noteconsip"></v-textarea>
      </v-row>
    </Confirm>
    <v-dialog v-model="dialogContatto" max-width="500px">
      <v-card class="pa-3">
        <v-card-title>
          <v-card color="blue" dark elevation="5" class="mr-5 text-center" width="35" height="35">
            <v-icon>mdi-account-circle</v-icon>
          </v-card>
          Contatto
        </v-card-title>
        <v-card-text v-if="contattoCorrente">
          <v-row>
            <v-col cols="3" class="font-weight-bold">Nome</v-col>
            <v-col>{{ contattoCorrente.referente_cognome }} {{ contattoCorrente.referente_nome }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="font-weight-bold">E-Mail</v-col>
            <v-col><a :href="'mailto:' + contattoCorrente.referente_email">{{
                contattoCorrente.referente_email
              }}</a></v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="font-weight-bold">Telefono</v-col>
            <v-col><a :href="'tel:' + contattoCorrente.referente_telefono">{{
                contattoCorrente.referente_telefono
              }}</a></v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="font-weight-bold">Fax</v-col>
            <v-col>{{ contattoCorrente.referente_fax }}</v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogContatto=false">Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import Editor from "@/components/Editor";
import LocalDate from "@/components/LocalDate";
import TextTruncate from "@/components/TextTruncate";
import Relation from "@/components/Relation";
import OrdinativoStato from "@/components/OrdinativoStato";
import {mapState} from "vuex";
import Confirm from "@/components/Confirm";
import Boolean from "@/components/Boolean";

export default {
  data: () => ({
    url: '/audit/ordinativo/',
    dialogAssocia: false,
    dialogAssegna: false,
    dialogPianifica: false,
    dialogUpload: false,
    dialogContatto: false,
    dialogValidaConsip: false,
    confermaLoading: false,
    selected: null,
    file: null,
    globalActions: [
      {key: 'associa', label: 'Associa a gruppo', auth: 'amministratore'},
      {key: 'assegna', label: 'Assegna a ispettore', auth: ['amministratore', 'pianificatore']},
      {
        key: 'pianifica',
        label: 'Pianifica valutazione',
        auth: ['amministratore', 'pianificatore', 'ispettore']
      },
      {key: 'sospendi', label: 'Sospendi', auth: ['amministratore', 'pianificatore']},
      {key: 'annulla', label: 'Annulla', auth: ['amministratore', 'pianificatore']},
      {key: 'sostituisci', label: 'Sostituisci', auth: ['amministratore', 'pianificatore']},
      {key: 'invalida', label: 'Invalida', auth: ['amministratore']},
      {key: 'valida-consip', label: 'Valida CONSIP', auth: ['amministratore']},
      {key: 'non-valida-consip', label: 'Non Validata CONSIP', auth: ['amministratore']},
    ],
    associaGruppo: null,
    assegnaUser: null,
    pianificaData: null,
    pianificaOra: null,
    noteconsip: null,
    contattoCorrente: null,
    loading_file: false,
    statistiche: [],
    convenzione: {},
    valida: {
      rapporto: null,
      data: null,
    },
    loadingRapporto: false
  }),
  async mounted() {
    await this.loadConvenzione();
    await this.loadStatistiche();
    await this.loadRegioni();
  },
  computed: {
    ...mapState(['dataset']),
    args() {
      const args = {convenzione: this.$route.params.id};
      args[this.$route.params.tipo] = true;
      return args;
    },
    fields() {
      const tipo = this.$route.params.tipo;
      if (tipo === 'pianificazione') {
        return [
          {text: 'CDC', value: 'ordine', align: 'right'},
          {text: 'PdV', value: 'amministrazione', align: 'right'},
          {text: 'Tipo', value: 's_convenzione.tipo'},
          {text: 'Stato', value: 'stato'},
          {text: 'Pianificazione', value: 'data_pianificazione'},
          {text: 'Descrizione', value: 'descrizione', sortable: false},
          {text: 'Referente', value: 'referente', sortable: false},
          {text: 'Gruppo', value: 's_gruppo.nome', sortable: false},
          {text: 'Ispettore', value: 's_ispettore.full_name', sortable: false},
        ];
      } else if (tipo === 'ispezioni') {
        if (this.user.ruolo === 'consip') {
          return [
            {text: 'CDC', value: 'ordine', align: 'right'},
            {text: 'PdV', value: 'amministrazione', align: 'right'},
            {text: 'Esecuzione', value: 'data_pianificazione'},
            {text: 'Descrizione', value: 'descrizione', sortable: false},
            {text: 'Ispettore', value: 's_ispettore.full_name', sortable: false},
          ];
        } else {
          return [
            {text: 'CDC', value: 'ordine', align: 'right'},
            {text: 'PdV', value: 'amministrazione', align: 'right'},
            {text: 'Stato', value: 'stato'},
            {text: 'Esecuzione', value: 'data_pianificazione'},
            {text: 'Validazione', value: 'data_validazione'},
            {text: 'Interrotta', value: 'interrotta'},
            {text: 'Cambio', value: 'cambio_codice'},
            {text: 'NCL', value: 'nc.lievi'},
            {text: 'NCI', value: 'nc.importanti'},
            {text: 'NCG', value: 'nc.gravi'},
            {text: 'Descrizione', value: 'descrizione', sortable: false},
            {text: 'Ispettore', value: 's_ispettore.full_name', sortable: false},
          ];
        }

      } else {
        return [
          {text: 'PdV', value: 'amministrazione'},
          {text: 'Fornitore', value: 's_fornitore.full_name',},
          {text: 'Totale Ispezioni', value: 'stato_requisiti'},
          {text: 'NCL', value: 'nc.lievi'},
          {text: 'NCI', value: 'nc.importanti'},
          {text: 'NCG', value: 'nc.gravi'},
          {text: 'NCG %', value: 'nc.gravi_perc'},
        ];
      }
    },
    filters() {
      const tipo = this.$route.params.tipo;
      if (tipo === 'pianificazione') {
        return [
          {field: 'stato', label: 'Stato', options: this.stati},
          {
            field: 'ispettore',
            label: 'Ispettore',
            type: 'ajax',
            url: '/backend/autocomplete/userprofile',
            filter: {ruoli: 'amministratore,ispettore'}
          },
          {field: 'ordine', label: 'Identificativo Ordine'},
          {field: 'amministrazione', label: 'PdV'},
          {field: 'data_pianificazione_inizio', label: 'Data Pianificazione Inizio', type: 'date'},
          {field: 'data_pianificazione_fine', label: 'Data Pianificazione Fine', type: 'date'},
        ];
      } else {
        return [
          {field: 'stato', label: 'Stato', options: this.stati},
          {
            field: 'ispettore',
            label: 'Ispettore',
            type: 'ajax',
            url: '/backend/autocomplete/userprofile',
            filter: {ruoli: 'amministratore,ispettore'}
          },
          {field: 'ordine', label: 'CDC'},
          {field: 'codice_lotto', label: 'PdV'},
          {field: 'data_pianificazione_inizio', label: 'Data Pianificazione Inizio', type: 'date'},
          {field: 'data_pianificazione_fine', label: 'Data Pianificazione Fine', type: 'date'},
        ];
      }
    },
    stati() {
      return this.dataset['ordinativo_stato'];
    }
  },
  methods: {
    refresh() {
      this.$refs.editor.refresh(true);
    },
    editor_refresh() {
      this.loadStatistiche();
    },
    async loadRegioni() {
      const res = await this.$http.get('/backend/regione/');
      if (res.ok) {
        this.filters.forEach(row => {
          if (row.field === 'regione') {
            row.options = res.result.data.results.map(item => ({value: item.id, text: item.descrizione}));
          }
        })
      }
    },
    async loadStatistiche() {
      const method = 'statistiche_' + this.$route.params.tipo;
      const res = await this.$http.get(`/audit/ordinativo/${method}/`, this.args);
      if (res.ok) {
        this.statistiche = res.result.data;
      }
    },
    async loadConvenzione() {
      const id = this.$route.params.id;
      const res = await this.$http.get(`/audit/convenzione/${id}/`);
      if (res.ok) {
        this.convenzione = res.result.data;
      }
    },
    async upload() {
      this.loading_file = true;
      const url = `/audit/convenzione/${this.$route.params.id}/ordinativo/upload`;
      await this.$http.postFile(url, this.file);
      this.dialogUpload = false;
      this.loading_file = false;
      this.file = null;
      await this.$refs.editor.refresh();
    },
    async globalAction(args) {
      this.selected = args.selected;
      let stato = null;
      switch (args.action) {
        case 'associa':
          this.associaGruppo = null;
          this.dialogAssocia = true;
          return;
        case 'assegna':
          this.assegnaUser = null;
          this.dialogAssegna = true;
          return;
        case 'pianifica':
          this.pianificaData = null;
          this.pianificaOra = null;
          this.dialogPianifica = true;
          return;
        case 'sospendi':
          stato = 'sospeso';
          break;
        case 'annulla':
          stato = 'annullato';
          break;
        case 'sostituisci':
          stato = 'sostituito';
          break;
        case 'valida':
          stato = 'validato';
          break;
        case 'invalida':
          stato = 'non_validato';
          break;
        case 'valida-consip':
          stato = 'validato-consip';
          break;
        case 'non-valida-consip':
          stato = 'non-validato-consip'
          this.dialogValidaConsip = true;
          this.noteconsip = null;
          return;
      }
      if (stato) {
        const ids = this.selected.map(row => row.id);
        let url = `/audit/ordinativo/cambio_stato/${stato}`;
        await this.$http.post(url, {ids});
        this.refresh();
      }
    },
    async associa() {
      const ids = this.selected.map(row => row.id);
      const data = {gruppo: this.associaGruppo, ids};
      await this.$http.post('/audit/ordinativo/cambio_stato/da_pianificare', data);
      this.dialogAssocia = false;
      this.refresh();
    },
    async assegna() {
      const ids = this.selected.map(row => row.id);
      const data = {user: this.assegnaUser, ids};
      await this.$http.post('/audit/ordinativo/cambio_stato/in_corso_di_pianificazione', data);
      this.dialogAssegna = false;
      this.refresh();
    },
    async pianifica() {
      const ids = this.selected.map(row => row.id);
      const data = {
        ids,
        data: this.pianificaData,
        ora: this.pianificaOra,
      };
      await this.$http.post('/audit/ordinativo/cambio_stato/pianificato', data);
      this.dialogPianifica = false;
      this.refresh();
    },
    async validaConsip() {
      const ids = this.selected.map(row => row.id);
      const data = {
        ids,
        noteconsip: this.noteconsip
      };
      await this.$http.post('/audit/ordinativo/cambio_stato/non-validato-consip', data);
      this.dialogValidaConsip = false;
      this.noteconsip = null;
      this.refresh();
    },
    contatto(item) {
      this.contattoCorrente = item;
      this.dialogContatto = true;
    },
    download_pdf(item) {
      this.$http.downloadFile(`/audit/ordinativo/${item.id}/download_pdf/`);
    },
    download_rapporto(item) {
      this.$http.downloadFile(`/audit/ordinativo/${item.id}/download_rapporto_caricato/`);
    },
    download_rapporto_validato(item) {
      this.$http.downloadFile(`/audit/ordinativo/${item.id}/download_rapporto_validato/`);
    },
    download_allegati(item) {
      this.$http.downloadFile(`/audit/ordinativo/${item.id}/download_allegati/`);
    },
    async valida_rapporto(item) {
      const url = `/audit/ordinativo/${item.id}/valida_rapporto/`;
      await this.$http.postForm(url, {...this.valida});
      this.refresh();
    },
    async export_rapporto() {
      this.loadingRapporto = true;
      const res = await this.$http.get(`/audit/convenzione/download_rapporto_excel?pk=${this.$route.params.id}`);
      this.loadingRapporto = false;
      console.log(res);
      this.$http.forceDownload(res.result.data)
    },
    export_convenzione() {
      this.$http.downloadFile(`/audit/convenzione/download_convenzione_excel?pk=${this.$route.params.id}`);
    }
  },
  components: {
    Boolean,
    OrdinativoStato,
    Relation,
    TextTruncate,
    Editor,
    LocalDate,
    Confirm,
  },
}
</script>

<style scoped>
.descrizione {
  max-width: 400px;
}
</style>